//export const merchantId = 'mab0450400a0162083@yesbank';
export const merchantId = 'ashishbhai.kanetiya@paytm';
//export const baseURL = 'https://share2win.in/server/';
export const baseURL = 'http://15.206.159.155/server/';
//export const baseURL = 'https://3.84.170.72:9010/';
 export  const merchantName = 'Share2Win'; // Replace with your merchant name
 export    const currency = 'INR'; // Replace with your currency code
 export   const note = 'captcha-champion'; // Replace with your transaction note
 export   const purpose = '00';
export const minAddFunds = 200;
